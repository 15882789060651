import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  height: 90vh;
  margin: 0;
`;

const NameHeader = styled.h1`
  font-size: 7.5rem;
  margin: 0;
  font-weight: 300;
`;

const LandingBio = () => (
  <StaticQuery
    query={graphql`
      query LandingSiteTitleQuery {
        site {
          siteMetadata {
            title
            subtitle
          }
        }
      }
    `}
    render={() => (
      <Container>
        <NameHeader>KAHRIS</NameHeader>
      </Container>
    )}
  />
);

NameHeader.propTypes = {
  siteTitle: PropTypes.string,
  subtitle: PropTypes.string
};

NameHeader.defaultProps = {
  siteTitle: ``,
  subtitle: ``
};

export default LandingBio;
